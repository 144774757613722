import tools from "../../../extends/tools";
export default {
    swiperOptions: {
        slidesPerView: 1, //显示个数
        direction: 'horizontal',
        pagination: {
            el: '.swiper-pagination'
        },
        loop: true,
    },
    goods: {
        pic: []
    },
    currentSpec: {},
    attrList: [],
    postdata: {},
    allSpecId: new Set(),
    initFlag: false
}