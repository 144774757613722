<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" style="overflow:auto;">
        <div style="padding:20px 0px;width:1200px;position:relatvie;margin:0 auto;" :style="{height:__tableHeight__+50+'px'}">
            <div style="text-align:center;position:relative;margin-bottom:30px;">
                <span style="font-size:26px;">商品详情</span>
            </div>
            <!-- 头部信息 -->
            <div class="header" style="display:flex">
                <div style="width:55%;background:#ccc;height:400px;position:relative;">
                    <swiper 
                        ref="mySwiper" 
                        :options="swiperOptions">
                        <swiper-slide v-for="(item,key) in goods.pic" :key="key">
                            <img :src="__baseUrl+'/'+item.pic" alt="">
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                    <div class="swiper-button-prev bt" @click="prev"></div>
                    <div class="swiper-button-next bt" @click="next"></div>
                </div>
                <!-- 信息展示 -->
                <div class="goods_info" style="width:45%;padding:0 0 0 15px;height:400px;overflow:auto;">
                    <p class="title" style="height:50px;border-bottom:1px solid #dedede;line-height:50px;font-size:24px;margin-bottom:10px;">
                        {{goods.name}}
                    </p>
                    <el-form :inline="true">
                        <el-form-item style="margin-right:25px;">
                            <span style="color:#888;">商品分类：</span>{{goods.classify_info?goods.classify_info.name:''}}
                        </el-form-item>
                        <el-form-item label="" style="margin-right:25px;">
                            <span style="color:#888;">商品编码：</span>{{goods.product_code}}
                        </el-form-item>
                        <el-form-item label="" style="margin-right:25px;">
                           <span style="color:#888;">商品条形码：</span> {{goods.bar_code}}
                        </el-form-item>
                    </el-form>
                    <el-form :inline="true">
                        <el-form-item label="">
                            价格: ￥ <span style="font-size:40px;color:#F46363;">{{setPrice(currentSpec.retail_price)}}</span> / {{goods.unit_info?goods.unit_info.name:''}}
                        </el-form-item>
                    </el-form>

                    <el-form :inline="true" v-for="(item,key) in attrListCpd"  :key="key">
                        <el-form-item :label="item.name">
                            <el-button v-for="(i,k) in item.dispose_info" 
                            @click="changeSpec(i,k,item)"
                            :key="k" size="small" 
                            :class="item.activeIndex === k?'active':''"
                            class="spec_item">{{i.value}}</el-button>
                        </el-form-item>
                    </el-form>
  
                    <el-form :inline="true">
                        <el-form-item label="库存上限:">
                            {{currentSpec.max_stock}}
                        </el-form-item>
                        <el-form-item label="库存下限:">
                            {{currentSpec.min_stock}}
                        </el-form-item>
                        <el-form-item label="保质期:">
                            {{goods.quality_guarantee_period}}
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div style="height:100px;display:flex;width:55%;overflow:auto;">
                <div class="thumb_item" :class="getSwiper().realIndex===key?'active':''" @click="slideTo(key)" v-for="(item,key) in goods.pic" :key="key">
                    <img :src="__baseUrl+'/'+item.pic" alt="">
                </div>
            </div>
            <!-- 详情 -->
            <div  class="add_split" style="margin-top:25px;">
                <div class="leftBar" style="height:33px;"></div>
                <div class="leftBarWord" style="font-size:18px;">商品详情</div>
            </div>

            <div class="details" v-html="goods.details" style="margin-top:15px;">
                
            </div>




        </div>

        <!-- 悬浮条 -->
        <div class="bottom_bar">
            <div style="width:90%;margin:0 auto;position:relative;">
                <div style="display:inline-block;position:absolute;top:0px;right:10px;">
                    <el-button @click="cancel">取消</el-button>
                    <el-button @click="copyRecord">复制</el-button>
                    <el-button @click="edit" type="primary">编辑</el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
import datas from "./objectData";
import methods from "./method";
//swiperjs
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'


export default {
    props:{
        
    },
    inject: ['selectSide'], 
    data(){
        return datas;
    },
    destroyed(){
        
    },
    computed:{
        attrListCpd(){
            let arr = [];
            this.attrList.forEach(element => {
                let flag = false;
                let s = element;
                element.dispose_info.forEach(i=>{
                    if(this.allSpecId.has(i.id+'')){
                        flag = true;
                        return;
                    }
                });

                if(flag){
                    arr.push(s);
                }
            });
            return arr;
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:methods,
    created(){
        this.initFlag = true;
    },
    mounted(){
        this.init();
    }   
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.bottom_bar{
    min-width:100%;height:55px;background:#fff;position:fixed;line-height: 55px;
    bottom:0px;left:0px;z-index: 99;line-height:55px;text-align: left;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);border:1px solid #EBEEF5;
    .button{
        font-size:16px;margin-left:30px;z-index: 99;top:0px;width:130px;
    }
}
.el-input__inner{
    width:100%;background: #fff !important;;
}
.rows.purchase .cols{
    height: 40px;
}

.swiper-container {
width: 100%;
height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 100%;
    width:100%;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    img{
        width:100%;
        height:100%;
    }
  }
  .bt{
      height:30px;width:30px;background-color:#ccc;border-radius:50%;position:absolute;
      top:50%;font-size:16px;
  }
  .bt::after{
      font-size:16px;
  }

  .spec_item{
      min-width:70px;
  }
  .spec_item.active{
      background:rgba(40, 208, 148, 1);color:#fff;
  }
  .thumb_item{
      width:100px;height:62px;margin-top:19px;margin-right:20px;cursor: pointer;
      border:5px solid #fff;border-radius:5px;
      img{
          width:100%;height:100%;
      }
  }
  .thumb_item.active{
      border:5px solid #006EFF;
  }

</style>