import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import { Base64 } from 'js-base64';

export default {
    init() {
        if (!this.$route.query.id) {
            this.$router.push({
                path: '/goods/lists'
            });
            return;
        }
        apis.goodsDetail({
            id: this.$route.query.id
        }).then(res => {
            this.goods = res.data;
            this.goods.details = this.goods.details ? Base64.decode(this.goods.details) : '';
            //所有属性列表
            this.attrList = this.goods.source.attribute;
            //判断出现过的spec_id,根据这个判断属性是否启用
            this.allSpecId = new Set();
            this.goods.specifications_info.forEach(element => {
                let arr = element.dispose.split(',');
                arr.forEach(i => {
                    this.allSpecId.add(i);
                })
            });

            this.attrList.forEach(element => {
                this.$set(element, 'activeIndex', 0);
            });

            this.setSpec();
        }).catch(err => {

            tools.err(err, this);
        });
    },

    getSwiper() {
        return this.$refs.mySwiper ? this.$refs.mySwiper.swiper : {};
    },
    //
    changeSpec(item, key, currentList) {
        // 样式聚焦
        currentList.activeIndex = key;
        this.setSpec();
    },
    setSpec() {
        // 获取当前点击的路径，再去属性列表中对比获取详细属性信息
        let spec = [];
        this.attrListCpd.forEach(i => {
            spec.push(i.dispose_info[i.activeIndex].id + '');
        });
        let specPath = spec.join(',');
        this.goods.specifications_info.forEach(i => {
            if (i.dispose === specPath) {
                this.currentSpec = i;
            }
        });
    },
    prev() {
        this.getSwiper().slidePrev();
    },
    next() {
        this.getSwiper().slideNext();
    },
    setThumbActive() {

    },
    slideTo(index) {
        this.getSwiper().slideTo(index);
    },
    cancel() {
        this.$router.back();
    },
    copyRecord() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/borrow/add',
            query: {
                id: id,
                is_copy: 1
            }
        });
    },
    edit() {
        let id = this.$route.query.id;
        this.$router.push({
            path: '/borrow/add',
            query: {
                id: id
            }
        });
    },
    setPrice(num) {
        return tools.setPrice(num);
    }

}